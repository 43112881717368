/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */
 // jshint ignore: start


(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        if($('.customer-logos').length) {
          $('.customer-logos').slick({
            slidesToShow: 4,
            slidesToScroll: 1,
            responsive: [
             {
               breakpoint: 991,
               settings: {
                 slidesToShow: 3,
                 slidesToScroll: 1,
                 arrows: false,
                 dots: true
               }
             },
             {
               breakpoint: 767,
               settings: {
                 slidesToShow: 1,
                 slidesToScroll: 1,
                 arrows: false,
                 dots: true
               }
             },
           ]
          });
        }

        var removeClass = true;
        $(".hamburger").click(function () {
            $(".hamburger").toggleClass('is-active');
            $("#sideNav").toggleClass('sideNav-open');
            $(".sideNavBody").toggleClass('sideNavBody-push');
            removeClass = false;
        });

        $(".sideNav").click(function() {
            removeClass = false;
        });

        $("html").click(function () {
            if (removeClass) {
                $(".hamburger").removeClass('is-active');
                $("#sideNav").removeClass('sideNav-open');
                $(".sideNavBody").removeClass('sideNavBody-push');
            }
            removeClass = true;
        });

        $('#fade-quote-carousel').carousel({ interval: 15000 });

        (function(){
          $('.carousel-showmanymoveone .item').each(function(){
            var itemToClone = $(this);

            for (var i=1;i<6;i++) {
              itemToClone = itemToClone.next();

              // wrap around if at end of item collection
              if (!itemToClone.length) {
                itemToClone = $(this).siblings(':first');
              }

              // grab item, clone, add marker class, add to collection
              itemToClone.children(':first-child').clone()
                  .addClass("cloneditem-"+(i))
                  .appendTo($(this));
            }
          });
        }());

        (function () {
            $(window).scroll(function () {
                if ($(this).scrollTop() > 100) {
                    $('.scrollup').fadeIn();
                } else {
                    $('.scrollup').fadeOut();
                }
            });
            $('.scrollup').click(function () {
                $("html, body").animate({
                    scrollTop: 0
                }, 600);
                return false;
            });
        }());

        (function initMap() {
          var w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
          var isDraggable = w > 480 ? true : false;

          var map = new google.maps.Map(document.getElementById('map'), {
            zoom: 6,
            scrollwheel: false,
            draggable: isDraggable,
            center: {lat: 45.5000, lng: -69.0000},
            disableDefaultUI: true,
            mapTypeId: google.maps.MapTypeId.ROAD
          });

          if(document.getElementById('contact-map')) {
            var contactMap = new google.maps.Map(document.getElementById('contact-map'), {
              zoom: 6,
              scrollwheel: false,
              draggable: isDraggable,
              center: {lat: 45.5000, lng: -69.0000},
              disableDefaultUI: true,
              mapTypeId: google.maps.MapTypeId.ROAD
            });
          }

          var maineMapPolygon = [
            {lat: 45.3425, lng: -71.0129},
          {lat: 45.3328, lng: -70.9525},
          {lat: 45.2294, lng: -70.8618},
          {lat: 45.3917, lng: -70.8247},
          {lat: 45.4274, lng: -70.7808},
          {lat: 45.3830, lng: -70.6380},
          {lat: 45.5092, lng: -70.7190},
          {lat: 45.6544, lng: -70.5721},
          {lat: 45.7292, lng: -70.3894},
          {lat: 45.7924, lng: -70.4169},
          {lat: 45.9368, lng: -70.2493},
          {lat: 45.9597, lng: -70.3098},
          {lat: 46.0923, lng: -70.2946},
          {lat: 46.0989, lng: -70.2589},
          {lat: 46.1342, lng: -70.2466},
          {lat: 46.1903, lng: -70.2905},
          {lat: 46.2710, lng: -70.2466},
          {lat: 46.3270, lng: -70.2040},
          {lat: 46.4151, lng: -70.0571},
          {lat: 46.6956, lng: -69.9994},
          {lat: 47.4550, lng: -69.2303},
          {lat: 47.4132, lng: -69.0381},
          {lat: 47.2578, lng: -69.0504},
          {lat: 47.1748, lng: -68.8843},
          {lat: 47.2643, lng: -68.6206},
          {lat: 47.3546, lng: -68.3350},
          {lat: 47.3165, lng: -68.1564},
          {lat: 47.1038, lng: -67.8804},
          {lat: 47.0664, lng: -67.7898},
          {lat: 45.9359, lng: -67.7802},
          {lat: 45.9177, lng: -67.7527},
          {lat: 45.7599, lng: -67.8090},
          {lat: 45.6208, lng: -67.6524},
          {lat: 45.5987, lng: -67.4533},
          {lat: 45.5044, lng: -67.4176},
          {lat: 45.4823, lng: -67.5014},
          {lat: 45.3714, lng: -67.4231},
          {lat: 45.2768, lng: -67.4863},
          {lat: 45.1297, lng: -67.3434},
          {lat: 45.1830, lng: -67.2487},
          {lat: 45.1230, lng: -67.1223},
          {lat: 44.8315, lng: -66.9672},
          {lat: 44.7409, lng: -66.8628},
          {lat: 44.4945, lng: -67.3105},
          {lat: 44.3268, lng: -67.9051},
          {lat: 43.8702, lng: -68.6673},
          {lat: 43.7274, lng: -68.8431},
          {lat: 43.6639, lng: -69.7137},
          {lat: 43.5625, lng: -70.0818},
          {lat: 42.9182, lng: -70.5569},
          {lat: 43.0649, lng: -70.7108},
          {lat: 43.1391, lng: -70.8302},
          {lat: 43.2292, lng: -70.8179},
          {lat: 43.3631, lng: -70.9799},
          {lat: 43.5675, lng: -70.9717},
          {lat: 45.3029, lng: -71.0829}
          ];

          var maineMap = new google.maps.Polygon({
            path: maineMapPolygon,
              strokeColor: '#FF0000',
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: '#FF0000',
            fillOpacity: 0.35
          });

          if(document.getElementById('contact-map')) {

            var maineContactMap = new google.maps.Polygon({
              path: maineMapPolygon,
                strokeColor: '#FF0000',
              strokeOpacity: 0.8,
              strokeWeight: 2,
              fillColor: '#FF0000',
              fillOpacity: 0.35
            });

            maineContactMap.setMap(contactMap);
          }

          maineMap.setMap(map);
        }());
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point
